export function findFirstFocusableElement(
  node: HTMLElement,
): HTMLElement | null {
  const focusableElements = node.querySelectorAll<HTMLElement>(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
  )

  return focusableElements[0] || null
}

export function setupTabTrapping(
  element: HTMLElement,
): (event: KeyboardEvent) => void {
  const focusableSelectors = [
    'a[href]',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'iframe',
    'object',
    'embed',
    '[contenteditable]',
    '[tabindex]:not([tabindex="-1"])',
  ]

  function isElementVisible(el: HTMLElement): boolean {
    return el.offsetWidth > 0 && el.offsetHeight > 0
  }

  const focusableElements = Array.from(
    element.querySelectorAll<HTMLElement>(focusableSelectors.join(', ')),
  ).filter(isElementVisible)
  const firstElement = focusableElements[0]
  const lastElement = focusableElements[focusableElements.length - 1]

  function handleTabEvent(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        // Shift + Tab
        if (document.activeElement === firstElement) {
          event.preventDefault()
          lastElement.focus()
        }
      } else {
        // Tab
        if (document.activeElement === lastElement) {
          event.preventDefault()
          firstElement.focus()
        }
      }
    }
  }

  element.addEventListener('keydown', handleTabEvent)

  return handleTabEvent // Return the handler for later removal
}

export function removeTabTrapping(
  element: HTMLElement,
  handleTabEvent: (event: KeyboardEvent) => void,
): void {
  element.removeEventListener('keydown', handleTabEvent)
}
